import { config } from '@plandi/common'
import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: config.sentryDSN,
  environment: config.env
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
